<template>
    <!-- :key="$route.fullPath" 解决了路由前缀相同时跳转不刷新 -->
    <router-view  />
</template>

<script>
export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
/* 基本样式 */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #F0F0F0;
}

</style>