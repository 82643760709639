import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/Login/login.vue')
    },
    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: () => import('@/views/Login/forgotpassword.vue')
    },
    {
        path: '/main',
        name: 'main',
        component: () => import('@/views/Main/index.vue'),
        redirect: "/desk",
        children: [
            // 收银页面  
            {
                path: "/cashier",
                component: () => import("@/views/Cashier/index.vue"),
            },
            // 餐台进入收银页面  
            {
                path: "/cashierone",
                component: () => import("@/views/Cashier/cashier.vue"),
            },
            // 桌台/店客
            {
                path: "/desk",
                component: () => import("@/views/Desk/index.vue"),
            },
            // 商品管理
            {
                path: "/commoditymanagement",
                component: () => import("@/views/CommodityManagement/index.vue"),
            },
            // 订单
            {
                path: "/order",
                component: () => import("@/views/Order/index.vue"),
            },
            // 会员管理
            {
                path: "/member",
                component: () => import("@/views/Member/index.vue"),
            },
            // 营收数据  
            {
                path: "/revenue",
                component: () => import("@/views/Revenue/index.vue"),
            },
        ]
    },
]

const router = new VueRouter({
    routes
})

export default router
